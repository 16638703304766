import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import styled, { injectGlobal } from "styled-components";

injectGlobal`
  body {
    font-family: Arial,
                 "微软雅黑", "Microsoft Yahei",
                 "文泉驛正黑", "WenQuanYi Zen Hei",
                 "儷黑 Pro", "LiHei Pro",
                 "微軟正黑體", "Microsoft JhengHei",
                 "新細明體", "PMingLiU",
                 sans-serif !important;
  }
  body > div:first-child > div:first-child {
    position: relative;
  }
`;

const Footer = styled.footer`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1.5rem
`;

const Copyright = styled.div`
  color: #999;
`;

const Layout = props => (
  <div>
    { props.children }
    <Footer className="py-1 text-center">
      <Copyright className="small copyright">© 2017 - {new Date().getFullYear()} meowmeow.love. All rights reserved.</Copyright>
    </Footer>
  </div>
);

Layout.propTypes = {
  children: PropTypes.element.isRequired
};

export default Layout;
