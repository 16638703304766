import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import IndexPage from './pages/index.js';
import StoriesPage from './pages/stories.js';
import NotFoundPage from './pages/404.js';

firebase.database().ref('/posts/').once('value').then( snapshot =>{
  var value = snapshot.val();
  console.log( 'val', value );
});

firebase.firestore().collection( 'posts' ).get().then( querySnapshot => {
  querySnapshot.forEach(( doc ) => {
  window.season = doc;
    console.log( 'firestore', JSON.stringify( doc.data() ) );
  });
});

const App = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/" exact component={ IndexPage } />
      <Route path="/stories" component={ StoriesPage } />
      <Route component={ NotFoundPage } />
    </Switch>
  </BrowserRouter>
);

ReactDOM.render( <App />, document.querySelector( '#root' ) );

if ( module.hot ) {
  module.hot.accept();
}
