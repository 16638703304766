import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import ReactMasonryComponent from 'react-masonry-component'

import Story from '../Story'
import pngPalmColor from '../../static/palm-color.png';
import pngFolders from '../../static/folders.png';

const daysMissed = () => {
  const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
  const datePassedAway  = new Date( 2017, 12, 26 )
  let today = new Date()
  today = new Date( today.getFullYear(), today.getMonth() + 1, today.getDate() )

  const daysMissed = Math.round( Math.abs( ( datePassedAway.getTime() - today.getTime() ) / ( oneDay ) ) )
  return daysMissed
}

const Jumbotron = styled.section`
  margin-bottom: 0 !important;
  background-color: #fff !important;
  border-radius: 0 !important;
`;

const Home = styled( Link )`
  color: inherit !important;
`;

const Container = styled.div`
  max-width: 40rem;
  letter-spacing: 3px;
`;

const JumbotronLead = styled.p`
  font-size: 1.25rem;
  font-weight: 300;
`;

const DaysMissed = styled.p`
  letter-spacing: 0 !important;
  font-size: 60% !important;
  opacity: 0.5 !important;
`;

const StoriesWrapper = styled.div`
  padding-bottom: 3rem;
  background-color: rgba(0,0,0,.1);
`;

const MasonryOptions = {
  itemSelector: '.grid-item',
  columnWidth: '.grid-sizer',
  percentPosition: true
};

class Stories extends React.Component {
  render() {
    return (
      <main role="main">
        { this.renderHeader() }
        <StoriesWrapper className="text-muted p5-5">
          { this.renderStories() }
        </StoriesWrapper>
      </main>
    )
  }
  renderHeader() {
    return (
      <Jumbotron className="jumbotron text-center">
        <Container className="container">
          <h1 className="jumbotron-heading font-weight-bold">
            <Home to="/">喵喵</Home>
          </h1>
          <JumbotronLead className="lead text-muted">你的一生，我十八年</JumbotronLead>
          <DaysMissed className="text-muted small mt-5 days-missed">— 想念你的日子已經 { daysMissed() } 天 —</DaysMissed>
        </Container>
      </Jumbotron>
    )
  }
  renderStories() {
    return (
      <div className="container">
        <ReactMasonryComponent
          className="grid"
          options={ MasonryOptions }
          disableImagesLoaded={ false }
          updateOnEachImageLoad={ true }
        >
          <div className="grid-sizer col-xs-12 col-sm-6 col-md-4" />
          <div className="grid-item col-xs-12 col-sm-6 col-md-4">
            <Story
              title="新工作"
              content={
                <div>
                  <p>
                    喵喵，我換了一個新工作。
                  </p>
                  <p>
                    雖然前一份工作才做不久，但是這個月初在機緣巧合下還是換了一個新的工作。這工作的壓力很大，大約是我做過的工作以來壓力最大的，節奏也很趕，工作型態完全不同，和當初想像的完全不一樣。這份工作很搶手，面試也很困難，所以並沒有什麼把握能錄取，最後拿到聘書的時候真的是喜出望外，也帶來了人生一個重要的轉折。過程中通過一關一關的面試，總是覺得你用了不可知的能力在幫助我達成想要的目標，就像今年來所遇到的一切困難，總是有種關關難過關關過的感覺在。一直以來我不是一個運氣好的人，所以如果沒有你的幫助是不可能發生這麼多需要運氣才能實現的事。
                  </p>
                  <p>
                    工作了一個月還是感覺不上手，很不能適應這樣的節奏，我想還需要一點時間吧。夏天過去，季節已經入秋了，今年一切發生的事實在太讓人措手不及，卻是轉眼間就要進入一年的尾聲。今年的每一個春夏秋冬都是你不在身邊的第一個季節，而去年每一個季節裡跟你相處的點點滴滴都還是記憶猶新。
                  </p>
                  <p>
                    如果你真的有神奇的能力，我們的心靈就會因此連通的，那就藉由思念來陪伴對方吧。
                  </p>
                </div>
              }
              writtenAt={ "Sep. 30, '18" } />
          </div>
          <div className="grid-item col-xs-12 col-sm-6 col-md-4">
            <Story
              title="車禍"
              content={
                <div>
                  <p>
                    喵喵，最近忙著很多事情，一直沒時間來給你寫日記。
                  </p>
                  <p>
                    爸爸的腰椎手術很成功，復原情況也非常好，完全改善了症狀。我想像中所有治療最好的結果幾乎就是如此，這件讓我擔心的事算是理想地解決了。喵喵，如果我能夠在你下半身行動不良剛發病的那一年讓你手術，會不會你現在還在我身邊呢？動物的醫療實在有太多的困難，一方面無法和你溝通，二方面無論是儀器或是環境，動物醫學都還遠不及人類醫學發展進步。我只能以我有限的知識和能力替你的健康做決定，也不清楚究竟是不是正確的選擇。但是時間無法倒流，這個問題只能永遠放在我心裡。
                  </p>
                  <p>
                    昨天早上騎車出門，路邊遇到了一隻小貓咪車禍。車子已經不在了，也不清楚車禍什麼時候發生，小貓咪的身邊有幾灘血跡。騎著機車經過，等反應過來的時候就已經遠離了事故現場一個街口。死亡並不讓我害怕，讓人心碎的是小貓咪的尾巴還在地上微弱的拍打，小生命最後還在掙扎著。我是不是應該去陪在牠的身邊，可是我又能做什麼？我能不能擔誤上班時間呢？動物醫院這麼早也還沒有開門，無法提供協助。需要聯絡救援團體嗎？有哪裡能夠聯絡呢？
                  </p>
                  <p>
                    如果牠已經死亡了，那麼整件事情便可以面對，就像幾個月前處理路邊一隻已經往生的貓咪一樣。死亡並不可怕，甚至會為了野貓的死亡慶幸，恭喜他們從這個世界解脫了。但面對死亡，無法提供協助卻是很可怕的。
                  </p>
                  <p>
                    喵喵，我唯一慶幸的是，你生命最後盡頭的那一段時間，我一直都能夠陪在你身邊照顧你。
                  </p>
                </div>
              }
              writtenAt={ "Jun. 22, '18" } />
          </div>
          <div className="grid-item col-xs-12 col-sm-6 col-md-4">
            <Story
              title="剪刀找貓"
              imgSrc={ 'http://www.tidf.org.tw/sites/www.tidf.org.tw/files/styles/node_films_slider_stage/public/imagecache/scissors_and_the_cat_1.jpg' }
              content={
                <div>
                  <p>
                    喵喵，今天在公視看到了一隻短片「剪刀找貓」。
                  </p>
                  <p>
                    侯季然的短片，紀錄他家走失的一隻貓。貓咪是用放養的方式照顧，某一天他出門後就沒有再回來。情續很內斂的紀錄著他們和貓的相處，雖然平淡，但是從剪刀找貓的這個儀式可以看得出來，他心裡頭還是掛念著那一隻貓的。
                  </p>
                  <p>
                    每一段與貓相處的方式都有類似的地方。每一隻貓咪幾乎都喜歡抓抓，都會用撒嬌的叫聲和人對話。但也有不同的地方。他的貓咪放養，所以天天出門去看看這個世界。相較之下，我把你養在家裡是好是壞來得多，一直是我心中無解的遺憾之一。
                  </p>
                  <p>
                    不過這就是每一個生命的命運吧。
                  </p>
                </div>
              }
              writtenAt={ "May. 29, '18" } />
          </div>
          <div className="grid-item col-xs-12 col-sm-6 col-md-4">
            <Story
              title="癌症"
              content={
                <div>
                  <p>
                    喵喵，爸爸的朋友昨天癌症過世了。他大我爸爸一歲。
                  </p>
                  <p>
                    因為癌症發現得晚，所以去年發現之後已經沒有化療的可能性來做治療了。說起來這是一件好事呢，畢竟化療也是很折磨的。正要退休，該享清福的日子正要開始，他卻突然發現得了癌症，好像人生到頭來也是一場夢。
                  </p>
                  <p>
                    這幾年身邊陸續有人離世，覺得人生真是相當無常。你去年底離開之後，我還是參不透死亡這件事情，對身邊人的離開總是依然覺得稠悵，更對父母的健康小心翼翼。對於「生死有命，富貴在天」這句話，越來覺得得很有道理，但是也沒有能因此變得豁達一點。
                  </p>
                  <p>
                    你生病的那段日子，一直在為了你後腿無力和日漸消瘦擔心，一整年都為了這件事鬱鬱寡歡。你走了快半年，日常生活裡越來越能接受你不在了的這個事實，但是一旦被勾起你生病時候的辛苦，還是很捨不得。貓咪的一年相當於人的五年，所以爸爸朋友從檢查出罹癌這一年來，相當於貓咪生病兩個月。你生病一年的這段時間，相當於人間五年，以人的角度來看，你也辛苦了一段不短的時間。你走了之後，其實很為了你高興解脫了，只是同時也為了生死兩隔而稠悵。
                  </p>
                </div>
              }
              writtenAt={ "May. 17, '18" } />
          </div>
          <div className="grid-item col-xs-12 col-sm-6 col-md-4">
            <Story
              title="夢境"
              content={
                <div>
                  <p>
                    喵喵，前幾天夢到你了。夢裡面你突然出現在我面前，我又驚又喜。
                    你像從前一樣很喜歡我摸摸你的頭和下巴，也會主動磨蹭我的手掌，感覺我們回到了從前親暱的時光。
                  </p>
                </div>
              }
              writtenAt={ "May. 17, '18" } />
          </div>
          <div className="grid-item col-xs-12 col-sm-6 col-md-4">
            <Story
              title="家人關係?"
              imgSrc={ '//docs.google.com/uc?id=1S1TmiC0EQ9C63wbGp4O_tg_TzK1ur1v4' }
              imgTakenAt="2009"
              content={
                <div>
                  <p>
                    喵喵，你該算是我的哪一種家屬呢？
                  </p>
                  <p>
                    雖然我沒有真正有一個孩子，不曉得照顧一個孩子是什麼感覺，但是因為一直負責你的生活起居，就像父母自然而然照顧著小孩一樣，而且是一個永遠長不大的小孩，一直停留在最可愛的嬰孩時期。如果要套用人的親屬關係在你身上，我一直覺得比較像你的爸爸，你比較像我兒子。
                  </p>
                  <p>
                    現在我跟自己父親相處的時候，常常會想，他對我的教養是不是就像是我對你的照顧一樣呢？
                  </p>
                  <p>
                  </p>
                </div>
              }
              writtenAt={ "Apr. 23, '18" } />
          </div>
          <div className="grid-item col-xs-12 col-sm-6 col-md-4">
            <Story
              title="百日"
              content={
                <div>
                  <p> 喵喵，你離開了一百天。 </p> <p> 一百天不算很久，你陪著我十八年都不知道過了幾個一百天。不過日子一天天疊起來，不知不覺中也是發生了一些變化。比較能習慣沒有你在身邊的日子了。前幾天回鄉下掃墓，探親的時候遇見了兩隻小貓，跟我當年剛收養你的時候一樣大。你不在了以後，我就沒有再接觸到貓咪，直到那兩隻小貓。其中一隻小貓很親人，想黏在我身上磨蹭。你一樣總是在我身邊跟來繞去，一樣在我腳邊磨蹭。 </p> <p> 一個月前把你的骨頭拿出來整理一次，現在排列得很整齊。你的骨頭是我能最靠近你的東西了。家裡的貓砂也都還在原地，你吃剩下的罐頭盒也都還留在廚房，幫你餵食跟擦藥的針筒紗布也都還在那張凳子上。其實環境沒什麼改變，頂多是一些地墊被收起來了，加上我晚上換到了另一間房睡覺，所以我們原本休息的房間床上比較空蕩一些。 </p> <p> 我把買給你的電熱毯拿來用，好像可以體會你躺在上面的感覺。它幫助我改善了酸痛，希望當時它也有幫助你休息。有空的話你回來我的夢裡，告訴我你喜不喜歡那個電毯吧。 </p> </div> } writtenAt={ "Apr. 8, '18" } /> </div> <div className="grid-item col-xs-12 col-sm-6 col-md-4"> <Story
              imgSrc={ '//docs.google.com/uc?id=16TcdhkH7yyxgaPjITVR58-Mcsm4ZCPgi' }
              title="脊椎骨刺"
              imgTakenAt="2017"
              content={
                <div>
                  <p>
                    喵喵，你都一直躺著，身體是不是其實很不舒服呢？
                  </p>
                  <p>
                    最近我的父親脊椎椎間盤突出，走路都會彎著腰，準備要進醫院動手術了。因為這個病症，腰酸，腳時常會發麻。身邊上一輩的人多多少少都開始出現類似的問題。我想我以後也會出現同樣的問題。喵喵你的不良於行，其實同樣是受到脊椎問題影響吧。雖然醫生說下肢無力有幾種可能性，甚至也會雙重影響，但是自從你拍了x光之後，我心裡覺得就是x光裡明顯突出的骨刺造成的。天天觀察著你，幾乎每半個月，你病程會發生一次明顯的變化，同時我的心理也就要煎熬一次。
                  </p>
                  <p>
                    因為爸爸的健康狀況，讓我比較起來，其實你比他還要嚴重許多吧。如果他的腳會發麻，那麼同樣的，你是不是從偶爾走路無力開始，就很不舒服了呢？雖然我知道你一定有不舒服的，但是實在很難瞭解每一個階段裡你承受的疼痛是什麼程度，你的腿是不是變得很麻很麻呢，還是根本麻木沒有感覺了？沒有再伸懶腰，是因為伸懶腰會疼痛，還是因為根本不能控制神經讓身體做出伸懶腰的動作呢？心中總是還有一些沒有解答的疑問。真的很希望能和你說話，能聽你親口告訴我你的感覺，那麼我就還能為你做更好的照顧。我真是心疼捨不得你辛苦呢。
                  </p>
                  <p>
                    謝謝你最後這段時間犧牲身體健康陪著我，讓我有時間能對你道別做心理準備。你用生命帶我上一堂關於時間和死亡的課程，但是這門課太深了，還有好多要學，一直到今天你都還在幫我上課呢。
                  </p>
                </div>
              }
              writtenAt={ "Mar. 30, '18" } />
          </div>
          <div className="grid-item col-xs-12 col-sm-6 col-md-4">
            <Story
              imgSrc={ '//docs.google.com/uc?id=1Te2qV4EZPLQWatXhQpf6QnW-E3i3ArIe' }
              title="偏小的體型"
              imgTakenAt="2015"
              content={
                <div>
                  <p>
                    喵喵敒懶腰嘍。
                  </p>
                  <p>
                    我一直感覺你的骨架比較小，身長比較短。就算你胖胖躺在我身上的時候，我的手還是很能環抱得住你。但是你火化完的骨頭好像並沒有比較小。你看伸懶腰的時候體型還是很長。是因為以前胖胖的比較圓，就不覺得身長了嗎？也不是呀，你瘦的時候的確感覺比其它貓咪體型小一點。你在發育期的時候也沒有營養不良啊，因為很快就胖起來了。你的體型小並不是我的錯覺，也不只我這麼覺得。這是怎麼一回事呢？我一直都覺得很幸運，喜歡你體型小小的。
                  </p>
                </div>
              }
              writtenAt={ "Mar. 29, '18" } />
          </div>
          <div className="grid-item col-xs-12 col-sm-6 col-md-4">
            <Story
              imgSrc={ '//docs.google.com/uc?id=1hfs7o84-2qZlZttl6WxuAN8vp_F-xhQD' }
              title="氣勢"
              imgTakenAt="2016"
              content={
                <div>
                  <p>
                    喵喵，你的這張照片拍得很碰巧，是我很喜歡的一張。你的爪痕留在墊子上，好像很有威嚴，實際上你只是放鬆在伸懶腰而已，想嚇唬誰呀？
                  </p>
                  <p>
                    你也很習慣我在你身邊陪著對吧？每一個動作都在跟我說話。
                  </p>
                </div>
              }
              writtenAt={ "Mar. 19, '18" } />
          </div>
          <div className="grid-item col-xs-12 col-sm-6 col-md-4">
            <Story
              imgSrc={ '//docs.google.com/uc?id=1IsEhiO1vk2GJvzv7JW6XZjd196gxmlHh' }
              title="風景"
              imgTakenAt="2011"
              content={
                <div>
                  <p>
                    喵喵，窗外的風景百看不膩對嗎。陽台一直是你最喜歡的地方之一，這一天就算窗簾沒有拉起來，你還是要鑽進去。一直以來跟在我身邊，只能委屈你在屋子裡活動了。住過這麼多地方，一半有陽台可以活動一半沒有，這個陽台算是其中最高的了，你還喜歡嗎？
                  </p>
                  <p>
                    你究竟是在看窗外的什麼呢？公車，行人，還是偶爾經過的小鳥呢？
                  </p>
                </div>
              }
              writtenAt={ "Mar. 10, '18" } />
          </div>
          <div className="grid-item col-xs-12 col-sm-6 col-md-4">
            <Story
              imgSrc={ '//docs.google.com/uc?id=1pk90NA122O964ks4wdInz-CQffbIzWxM' }
              title="肥胖"
              imgTakenAt="2014"
              content={
                <div>
                  <p>
                    喵喵，這一張照片最展現你肥滋滋的肚腩，一點都不害差嘛你。你以前就是這麼胖，你的生命中前十五年都這麼胖。照片因為角度看來比你實際上胖得多，看來還真是有點誇張。
                  </p>
                  <p>
                    不過胖得好。你瘦的時候實在讓我心疼。
                  </p>
                </div>
              }
              writtenAt={ "Feb. 23, '18" } />
          </div>
          <div className="grid-item col-xs-12 col-sm-6 col-md-4">
            <Story
              imgSrc={ '//docs.google.com/uc?id=1UYyj0cJpuHyVLAZbvAENq65jnkIT24tx' }
              title="在看我嗎？"
              imgTakenAt="2009"
              content={
                <div>
                  <p>
                    喵喵，躲在棉被裡面偷看我覺得很好玩對嗎？逗你的時候你都很給面子。
                  </p>
                  <p>
                    最近遇到很多不順利的事，但是覺得你好像在我身邊保護我。我想你現在一定還在我身邊看顧著我吧。
                  </p>
                </div>
              }
              writtenAt={ "Feb. 14, '18" } />
          </div>
          <div className="grid-item col-xs-12 col-sm-6 col-md-4">
            <Story
              imgSrc={ '//docs.google.com/uc?id=1Af5KoIjUSEE3tOBhw_XhWZjwE3CFAdXa' }
              title="最早照片"
              imgTakenAt="2003"
              content={
                <div>
                  <p>
                    喵喵，你的相簿裡最早的一張照片是03年的這一張。你看你還在撒嬌。
                  </p>
                  <p>
                    二十年前大家都還是在用底片相機，數位相機才剛剛出來。
                    底片相機一台一千多，我買了一台。記得有拍了一些你小時候的相片，但是一直沒沖洗出來。
                    所以你小時候的樣子只在我的記憶裡。
                    後來上大學買了數位相機，也許是那時候備份還不齊全，現在留下最早的照片就是這張了。
                  </p>
                  <p>
                    那時候你三歲，雖然相片不清晰，但是很珍貴。
                  </p>
                </div>
              }
              writtenAt={ "Feb. 13, '18" } />
          </div>
          <div className="grid-item col-xs-12 col-sm-6 col-md-4">
            <Story
              imgSrc={ pngFolders }
              title="資料備份"
              imgTakenAt="2018"
              content={
                <div>
                  <p>
                    喵喵，你走了以後我花了好幾天把你的照片跟影片整理好，
                    然後上傳到雲端備份。
                    大部分的照片都已經上傳了，剩下去年2017年的資料還沒好。
                  </p>
                  <p>
                    這禮拜，我的筆電正用到一半突然壞掉，
                    心情真是七上八下，不知道硬碟裡的資料會不會受損。
                    雖然是固態硬碟，資料可以保留的機會比較大，
                    但是總是不能確定。
                    買了轉接卡來忙了一番，總算是把資料讀出來了。
                    趕緊把2017年的資料上傳到雲端去。
                  </p>
                  <p>
                    雖然筆電才新買半年，但是比起你的資料，重要性根本是天差地遠。
                    喵喵，總之你的紀錄已經安全地備份起來嘍。
                  </p>
                </div>
              }
              writtenAt={ "Feb. 4, '18" } />
          </div>
          <div className="grid-item col-xs-12 col-sm-6 col-md-4">
            <Story
              imgSrc={ '//docs.google.com/uc?id=1MZtGj2JH-z86Km__vUw7155eHvVL51s9' }
              title="滿月"
              imgTakenAt="2017"
              content={
                <div>
                  <p>
                    喵喵，今天26號，日子算到今天，你離開我身邊滿一個月。
                    一個月來每天早上起床、每天晚上回家，都會叫一聲喵喵跟你打招呼。
                    就像以前每一趟回家，就是先叫你一聲，看看你在哪裡。
                  </p>
                  <p>
                    家裡聞得到你的味道就會讓我心安。一直不太敢開窗，怕味道消失。
                    有幾次為了通風，回家後你的味道消失了，心裡突然感覺慌張起來。
                    我太習慣你在我身邊了。
                  </p>
                  <p>
                    仔細想想，味道是從我們晚上睡覺的房間，慢慢飄散出來房子裡的。
                    味道最明顯是在房間裡浴室。
                    你洗澡的時候用我的沐浴乳，晚上抱著你睡覺的時候，我身上味道也傳到你身上。
                    所以你的味道就是我的味道啊。
                  </p>
                  <p>
                    喵喵，你知道我很想你嗎？無論你在哪裡，以後我們一定還要再見面。
                  </p>
                </div>
              }
              writtenAt={ "Jan. 26, '18" } />
          </div>
          <div className="grid-item col-xs-12 col-sm-6 col-md-4">
            <Story
              imgSrc={ '//docs.google.com/uc?id=1NilLPE0W1eb1Bm87Mgih4fn6WOIgJN6F' }
              imgTakenAt="2005"
              title="睡姿最愛"
              content={
                <div>
                  <p>這是你一輩子最喜歡的睡姿。</p>
                  <p>以前我常晚睡，凌晨兩點多才會熄燈，睡前的時間房間燈都是亮晃晃的。在我關燈就寢前，你大概是不得已只能配合我的作息，在睡覺的時候形成了這個姿勢。</p>
                  <p>你會先把兩隻手交錯，手腕折彎握著，然後尾巴撢撢收過來，最後臉埋進去。這樣能躲開日光燈了對吧，你的一舉一動我可是解讀得出來的，你就是孫悟空，也逃不出如來佛的眼皮子底下。</p>
                  <p>18歲的這一年都沒看你用這個姿勢睡覺了，應該是下背骨刺讓你在這個姿勢不舒服會痛吧。喵喵，我都知道喔，所以最後這一年我才一直把你抱在身上。</p>
                </div>
              }
              writtenAt={ "Jan. 20, '18" } />
          </div>
          <div className="grid-item col-xs-12 col-sm-6 col-md-4">
            <Story
              imgSrc={ '//docs.google.com/uc?id=1PMU3Gg5WxtFvlC5EEbowp_BudvyXuRvs' }
              imgTakenAt="2004"
              title="健健康康"
              content={
                <div>
                  <p>喵喵你4歲的時候還沒有便秘的毛病，我放飯隨意，只要看到你的碗裡飼料快沒了，就往裡頭加。</p>
                  <p>
                    碗裡頭整天都能有飼料吃，所以你還不覺得吃飯有什麼，反正經過碗邊就吃幾口。
                    餓也吃幾口，不餓也吃幾口。
                  </p>
                  <p>
                    這時候的你最胖了，拍照臨時放了一隻螢光筆當比例尺。
                    健健康康的你照顧起來很輕鬆呀，每天就是飼料、水、貓砂，飼料、水、貓砂。
                    因為總有東西吃，你還沒有養成每天早上叫醒我起床放飯的習慣。
                    日子過得真愜意。
                  </p>
                  <p>
                    喵喵你的肚皮就是這兩三年吃撐起來的，沒有脖子也沒有腰身。
                    幾年之後飲食計畫開始，每次剃完毛你肚子總是垂著一層皮。
                  </p>
                </div>
              }
              writtenAt={ "Jan. 18, '18" } />
          </div>
          <div className="grid-item col-xs-12 col-sm-6 col-md-4">
            <Story
              imgSrc={ pngPalmColor }
              title="手掌顏色"
              content={
                <div>
                  <p>
                    年輕的你手掌是黑色的，那時候八歲。
                    雖然七歲已經開始換食高齡貓飼料，但是八歲的你在我心中還正當壯年。
                  </p>
                  <p>
                    年老的你手掌褪色了，這時候十八歲。
                    粉紅色的掌心紅撲撲的，雖然褪色，卻是比年輕的手掌還要有朝氣呢。
                  </p>
                </div>
              }
              writtenAt={ "Jan. 10, '18" } />
          </div>
        </ReactMasonryComponent>
      </div>
    )
  }
}

export default Stories
