import React from 'react'
import Layout from '../layout/';
import Stories from '../components/Stories'

const StoriesPage = () => (
  <Layout>
    <Stories />
  </Layout>
);

export default StoriesPage
