import React from 'react';
import Layout from '../layout/';
import Home from '../components/Home';

const IndexPage = () => (
  <Layout>
    <Home />
  </Layout>
);

export default IndexPage;
