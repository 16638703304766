import React from 'react'
import PropTypes from 'prop-types'
import styled from "styled-components";

const Card = styled.div`
    margin-top: 3rem;
    border: none !important;
`;

const CardImg = styled.div`
    position: relative;
`;

const ImgTakenAt = styled.span`
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0 5px;
    background: rgba(0, 0, 0, .5);
    border-top-left-radius: 5px;
`;

const CardBody = styled.div`
    border: 1px solid rgba( 0, 0, 0, .125 );
    border-top: none;
`;

const CardText = styled.div`
    font-size: 85%;
`;

const Story = ( { imgSrc, imgTakenAt, title, content, writtenAt } ) => (
  <Card className="card">
    <CardImg>
      <img className="card-img-top" src={ imgSrc } />
      { imgTakenAt && <ImgTakenAt className="small">{ imgTakenAt }</ImgTakenAt> }
    </CardImg>
    <CardBody className="card-body">
      <h5 className="card-title">{ title }</h5>
      <CardText className="card-text">
        { content }
        <div className="written-at">{ writtenAt }</div>
      </CardText>
    </CardBody>
  </Card>
)

Story.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  imgTakenAt: PropTypes.string,
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  writtenAt: PropTypes.string.isRequired
}

export default Story
