import React from 'react'
import { Link } from 'react-router-dom'
import styled, { injectGlobal, keyframes } from "styled-components";

injectGlobal`
    body {
        background-color: #333!important;
        color: #333 !important;
    }
`;

const HomeWrapper = styled.div`
    color: #fff !important;
    text-align: center !important;
    text-shadow: 0 .05rem .1rem rgba(0,0,0,.5) !important;
    position: relative;
    display: table;
    width: 100%;
    height: 100vh;
    min-height: 100%;
    padding-bottom: 5rem;
    box-shadow: inset 0 0 5rem rgba(0,0,0,.5);
`;

const HomeWrapperInner = styled.div`
    display: table-cell;
    @media (min-width:40em) {
      vertical-align: middle;
    }
`;

const CoverContainer = styled.div`
    margin-right: auto;
    margin-left: auto;
    @media (min-width:40em) {
        width: 100%;
    }
    @media (min-width:62em) {
        width: 42rem;
    }
`;

const PhotoWrapper = styled.div`
    position: relative;
    width: 100% !important;
    overflow: hidden;
    @media (min-width:600px) {
        border-radius:.25rem !important;
        max-width: 75% !important;
    }
`;

const zoom = keyframes`
  from {
    transform: scale(1, 1);
  }
  to {
    transform: scale(1.1, 1.1);
  }
`;

const Photo = styled.img`
    animation: ${ zoom } 20s;
`;

const Date = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: .75rem;
    background-color: rgba(0,0,0,.5);
    text-shadow: none;
    padding: 0 5px;
    @media (min-width:600px) {
        border-bottom-right-radius: .25rem;
    }
`;

const KnowMore = styled( Link )`
    font-size: .75em;
    color: #fff !important;
    text-decoration: none !important;
    &:hover {
      opacity: 0.7;
    }
    &:hover .tooltip-stories {
        display: inline-block;
    }
`;

const TooltipStories = styled.div`
    display: none;
    position: relative ;
    border-radius: 2px;
    padding: 2px 5px;
    text-shadow: none !important;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
`;

import png1 from '../../static/1.png'
import png2 from '../../static/2.png'
import png3 from '../../static/3.png'

const Home = () => (
  <HomeWrapper className="home-wrapper">
    <HomeWrapperInner className="home-wrapper-inner">
      <CoverContainer className="cover-container">
        <main className="text-center">
          <div className="img">
            <div
              id="carouselExampleIndicators"
              className="carousel slide"
              data-ride="carousel"
              data-interval="15000"
            >
              <ol className="carousel-indicators">
                <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active" />
                <li data-target="#carouselExampleIndicators" data-slide-to="1" />
                <li data-target="#carouselExampleIndicators" data-slide-to="2" />
              </ol>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <PhotoWrapper className="photo-wrapper mx-auto">
                    <Photo className="d-block w-100 photo" src={ png1 } />
                    <Date className="date">{ "May '13" }</Date>
                  </PhotoWrapper>
                </div>
                <div className="carousel-item">
                  <PhotoWrapper className="photo-wrapper mx-auto">
                    <Photo className="d-block w-100 photo" src={ png2 } />
                    <Date className="date">{ "June '13" }</Date>
                  </PhotoWrapper>
                </div>
                <div className="carousel-item">
                  <PhotoWrapper className="photo-wrapper mx-auto">
                    <Photo className="d-block w-100 photo" src={ png3 } />
                    <Date className="date">{ "June '13" }</Date>
                  </PhotoWrapper>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3">
            <h1 className="mb-0">Meow Meow</h1>
            <h4 className="d-inline-block" title="Novembver 1999 - December 2017">November 1999 to December 2017</h4>
            <div className="mt-3">
              Thank you for the companionship.
            </div>
          </div>
        </main>
      </CoverContainer>
    </HomeWrapperInner>
  </HomeWrapper>
)

export default Home
